.wysiwyg-editor {
  position: relative;
  padding-top: 40px;
}

.wysiwyg-editor > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.wysiwyg-editor .DraftEditor-editorContainer > .public-DraftEditor-content {
  height: calc(100vh - 150px) !important;
  padding: 0 30px;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.DraftEditor-editorContainer > .public-DraftEditor-content {
  min-height: 140px;
  overflow: auto;
  height: 100vh !important;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}
