@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.heading {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600{
  font-weight: 600!important;
}
.font-weight-700{
  font-weight: 700!important;
}
