.single-chart {
  justify-content: space-around ;
  text-align: center;
  margin-right: "25px";
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #e4e5eb;
  stroke-width: 0.5;
}

.circle {
  fill: none;
  stroke-width: 1.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  font-family: Montserrat;
  font-weight: 600;
  text-align: center;
  color: #181818;
  text-anchor: middle;
}

.sub-text {
  opacity: 0.6;
  font-family: Montserrat;
  font-weight: 600;
  text-align: center;
  color: #818181;
  text-anchor: middle;
}