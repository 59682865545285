@media print {
  
  #root {
    visibility: hidden;
  }

  .p-exact {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .print-section {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .p-height-auto {
    height: auto !important;
  }

  .p-height-unset {
    height: unset !important;
  }

  .p-overflow-auto {
    overflow: auto !important;
  }

  .p-overflow-unset {
    overflow: unset !important;
  }

  .p-position-unset {
    position: unset !important;
  }

  .p-overflow-visible {
    overflow: visible !important;
  }

  html, body {
    width: 210mm !important;
    height: auto;
    overflow-x: hidden;
  }
}

@page {
  size: A4;
  margin: 8mm;
}