// variables that need to be set before bootstrap reboot can be used safely
$body-bg: #f5f5f5;
$body-color: #42484b;
$primary: #4089f7; // Also see PXMaterialUITheme.js
$secondary: #f7ae40; // Also see PXMaterialUITheme.js
$darkBlue:#0066FF;
$font-family-base: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
$enable-gradients: true;
$border-color: #D8DCE0;
// make breakpoints of material-ui and bootstrap the same
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1440px,
  xl: 1920px
);
$container-max-widths: (
  xl: 1440px
);
$grid-gutter-width: 30px;
$grid-gutter-widths: (
  ("default", $grid-gutter-width),
  (600, 60px),
  (960, 80px),
  (1440, 120px)
);
$spacers: (
  2px:2px,
  3px:3px,
  4px:4px,
  5: 5px,
  6: 6px,
  8: 8px,
  10: 10px,
  11: 11px,
  12: 12px,
  14: 14px,
  15: 15px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  25: 25px,
  30: 30px,
  33: 33px,
  35: 35px,
  40: 40px,
  42: 42px,
  45: 45px,
  50: 50px,
  60: 60px,
);
