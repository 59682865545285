input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch + div > div {
	float: left;
	border-radius: inherit;
	background: #ffffff;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
  margin-top: 7.5px;
	margin-left: 2px;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 44px;
	height: 44px;
	margin-top: 1px;
}